import React from "react";
import {Box, Button, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";

export const VideoPresentation = ({presentation, doDownload}) => {
    const {t} = useTranslation()
  return(
      <Box sx={{mt:3, borderBottom:'1px solid', borderColor:'divider'}}>
          <Typography variant={"h5"} sx={{my: 1}} fontWeight={"bold"}>
              {presentation.name}
          </Typography>
          <Box style={{width: '100%', maxWidth: '50rem'}} sx={{mb:1}}>
              <div style={{padding: '56.25% 0 0 0', position: 'relative'}}>
                  <iframe
                      src={presentation.video.link}
                      frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen
                      style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%'}}
                      title={presentation.name}></iframe>
              </div>
          </Box>
          {(presentation.ppt!==null || presentation.pdf!==null) && <Typography variant={"h6"} sx={{my: 1}}>
              {t('content.presentations')}: {presentation.ppt!==null &&
              <Button onClick={() => doDownload(presentation.id)} href={presentation.ppt} target={'_blank'}>PPT</Button>}{presentation.pdf!==null &&
              <Button onClick={() => doDownload(presentation.id)} href={presentation.pdf} target={'_blank'}>PDF</Button>}
          </Typography>}
      </Box>
  )
}

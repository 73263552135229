import React from "react";
import HeaderLayout from "../Layouts/HeaderLayout";
import {Box, Container, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";

const sections = [
    {
        title: 'HOTELS',
        content: <Box>
            <Typography  sx={{fontSize: 'min(1.38rem)'}}>
                <b>W IBIZA HOTEL</b>
                <br/>
                Carrer Ricardo Curtoys Gotarredona, 37
                <br/>
                07840 Santa Eulalia del Río
                <br/>
                Ibiza, Illes Balears, Spagna
                <br/><br/>
                <b>CHECK IN TIME from 3 pm</b>
                <br/>
                <b>CHECK OUT TIME by 12 pm</b>
                <br/><br/>
                <b>Distance from Ibiza Airport to W Ibiza Hotel: 21.6 km</b>
                <br/><br/>
                <b>RIOMAR HOTEL</b>
                <br/>
                Calle Del Rio, 48
                <br/>
                07840 Santa Eulària des Riu
                <br/>
                Ibiza, Illes Balears, Spagna
                <br/><br/>
                <b>CHECK IN TIME from 3 pm</b>
                <br/>
                <b>CHECK OUT TIME by 12 pm</b>
                <br/><br/>
                <b>Distance from Ibiza Airport to Riomar Hotel: 21.8 km</b>
                <br/><br/>
                <b>Distance from W Hotel to Riomar Hotel: 200mt.</b>
            </Typography>
            <Box sx={{width:'100%', margin:'auto', pt:4}}>
                <img style={{width:'100%'}} src={require('../assets/images/hotel-map.jpg')}/>
            </Box>
        </Box>
    },
    {
        title:'CONGRESS CENTER',
        content: <Typography>
            <b>PALACIO DE CONGRESOS</b>
            <br/>
            Avenida, Salvador Camacho, 9-11
            <br/>
            07840 Santa Eulària des Riu
            <br/>
            Ibiza, Illes Balears, Spagna
            <br/><br/>
            Guests will be able to reach the congress center through a pleasant seaside walk from the hotel.
        </Typography>
    },
    {
        title:'ORGANIZING OFFICE INFO',
        content: <Typography>
            For any further need you can contact the <b>organizing office</b>:
            <br/><br/>
            <b>SS24 Global Sales Meeting</b>
            <br/>
            Logistics Organizing Secretariat
            <br/>
            Mobile ph.: +39 334 6223502
            <br/>
            Email: globalsalesmeeting@arenasport.com
            <br/>
            Monday to Friday from 9 am to 6 pm (CEST)
        </Typography>
    }
]

const TravelInfo = () => {
    const {t} = useTranslation();

    return (
        <HeaderLayout>
            <Container maxWidth={'md'} sx={{mt: 10, mb: 2}}>
                <Typography variant={"h2"}>
                    Travel Info
                </Typography>
                {sections.map((section, index) => (
                    <Box key={index} sx={{py: 1}}>
                        <Typography variant={'h4'} fontWeight={'bold'} sx={{py:1}}>
                            {section.title}
                        </Typography>
                        {section.content}
                    </Box>
                ))}
            </Container>
        </HeaderLayout>
    )
}

export default TravelInfo

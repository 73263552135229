import React, {useState} from 'react'
import {Backdrop, Box, CircularProgress} from "@mui/material";
import {Trans, useTranslation} from "react-i18next";

const Day2 = ({handleToggle}) => {
    const {t} = useTranslation()

    return (
        <Box>
            <p style={{fontSize: 'min(1.38rem)'}}>
                7.00 AM - <b>Running Session </b>(45 min) or <b>Yoga Session</b> (45 min)
                <br/>
                (optional activities - reservation upon request)
                <br/>
                <b><i>meeting at Riomar Hotel Hall</i></b>
                <br/>
                <br/>
                8.30 AM - Rendezvous at <b><i>W Hotel</i></b> and Departure to <b><i>Palacio de Congresos</i></b> (by foot - 20 mins walk)
                <br/>
                <br/>
                <b>SALES, MARKETING AND PRODUCT PRESENTATIONS (LIVE STREAMING)</b>
                <br/>
                <b><i>Palacio de Congresos - Ground Floor</i></b>
                <br/>
                9.00 AM - Sales results and strategies - EU & Int'l Markets
                <br/>
                9.30 AM - Sales results and strategies - US Market
                <br/>
                10.00 AM - Brand communication strategy
                <br/>
                10.15 AM - Beachwear project presentation
                <br/>
                10.35 AM - SS24 Beachwear collection marketing presentation
                <br/>
                <br/>
                10.45 AM - Coffee Break
                <br/>
                <b><i>Palacio de Congresos - Garden</i></b>
                <br/>
                <br/>
                11.15 AM - SS24 Collection design & inspiration
                <br/>
                11.20 AM - SS24 Beachwear product workshop
                <br/>
                12.15 AM - SS24 Fun Planet collection marketing presentation & product workshop
                <br/>
                12.30 AM - SS24 Equipment marketing & product presentation
                <br/>

                <br/>
                1.15 PM - Lunch
                <br/>
                <b><i>Palacio de Congresos - Lunch area first floor</i></b>
                <br/>
                <br/>
                <b>SALES, MARKETING AND PRODUCT PRESENTATIONS (LIVE STREAMING)</b>
                <br/>
                2.45 PM - Powerskin Racing marketing presentation & product workshop
                <br/>
                <br/>
                3.30 PM - Coffee Break
                <br/>
                <b><i>Palacio de Congresos - Garden</i></b>
                <br/>
                <br/>
                4.00 PM - SS24 Swimwear collection introduction & marketing presentation
                <br/>
                4.30 PM - SS24 Swimwear product workshop
                <br/>
                5.30 PM - Wrap up
                <br/>
                <br/>
                from 9.00 AM to 6.00 PM - Touch and Feel
                <br/>
                <b><i>Palacio de Congresos - First Floor</i></b>
                <br/>
                <br/>
                8.30 PM - Dinner at <b><i>W Hotel - Lounge & Pool area</i></b>
                <br/>
            </p>
        </Box>
    )
}

export default Day2

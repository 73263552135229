import React from "react";
import {
    Box,
    Grid,
    Typography
} from "@mui/material";
import {Trans, useTranslation} from "react-i18next";
import {StepContent} from "./StepContent";
import RoommatesForm from "./Step4/RoommatesForm";

export const StepComponent = ({step, handleCompileData, userData, profile}) => {
    const {t} = useTranslation()

    return (
        <Box sx={{p: 1}}>
            <Typography variant={'h4'} sx={{pb:1}}>{t(step.titolo)}</Typography>
            {step.subtitle && <Typography variant={'h6'} sx={{pb: 2}}><Trans i18nKey={t(step.subtitle)} components={{2: <sup/>}}/></Typography>}
            <Grid container spacing={2}>
                {step.campi.map(c => {
                        if (c.details) {
                            return (
                                <Grid item xs={12}>
                                    <Typography variant={'h6'}>{t(c.title)}</Typography>
                                    <Grid container spacing={3}>
                                        {c.details.map(d => {
                                                return (
                                                    <StepContent profile={profile} handleCompileData={handleCompileData}
                                                                 userData={userData} c={d} type={c.id}/>
                                                )
                                            }
                                        )}
                                    </Grid>
                                </Grid>
                            )
                        } else {
                            if (c.conditions) {
                                let render = []
                                c.conditions.predicates.forEach(predicate => {
                                    let checked = userData.values[predicate.id] === predicate.value
                                    render.push(checked)
                                })
                                if (!render.includes(false)) {
                                    return <StepContent profile={profile} handleCompileData={handleCompileData}
                                                        userData={userData} c={c}/>
                                } else {
                                    return ''
                                }
                            } else {
                                return <StepContent profile={profile} handleCompileData={handleCompileData}
                                                    userData={userData} c={c}/>
                            }
                        }
                    }
                )}
            </Grid>
            {userData.step === 5 &&
                (userData.values.bed !== ('alone') && userData.values.bed !== ('')) &&
                <RoommatesForm profile={profile} userData={userData} handleCompileData={handleCompileData} step={step}/>
            }

        </Box>
    )
}

import * as React from "react";
import {Routes, Route, Navigate} from "react-router-dom";
import "./App.css";
import Register from "./pages/Register";
import Login from "./pages/Login";
import {useEffect, useState} from "react";
import AuthService from "./services/auth.service";
import {CssBaseline, ThemeProvider} from "@mui/material";
import {arenaTheme} from "./theme/arenaTheme";
import ProtectedRoute from "./components/ProtectedRoute";
import Home from "./pages/Home";
import Profile from "./pages/Profile";
import UserService from "./services/user.service";
import RegisteredRoute from "./components/RegisteredRoute";
import PreRegister from "./pages/PreRegister";
import ThankYouStreaming from "./pages/ThankYouStreaming";
import TravelInfo from "./pages/TravelInfo";
import Streaming from "./pages/Streaming";
import CovidUpdate from "./pages/CovidUpdate";
import Recover from "./pages/Recover";
import DailyAgenda from "./pages/DailyAgenda";
import EventContents from "./pages/EventContents";
import {RegistrationSuccess} from "./pages/RegistrationSuccess";
import {CompensationForm} from "./pages/CompensationForm";
import {SuccessStreaming} from "./pages/SuccessStreaming";

function App() {
    const [currentUser, setCurrentUser] = useState(undefined);
    const [userData, setUserData] = useState({})
    const [isThere, setIsThere] = useState(false);
    const [isRemote, setIsRemote] = useState(false);

    useEffect(async () => {
        const user = AuthService.getCurrentUser();
        const userData = await UserService.getUserData()
        if (user) {
            setCurrentUser(user);
            setUserData(userData.data.user)
        }
    }, []);

    return (
        <ThemeProvider theme={arenaTheme}>
            <CssBaseline/>
            <Routes>
                <Route path={'/login'} element={<Login/>}/>
                <Route path={'/recover-password'} element={<Recover/>}/>

                <Route element={<RegisteredRoute user={localStorage.user} userData={userData}/>}>
                    <Route path={'/register'} element={<Navigate to={'/'}/>}/>
                    <Route path={'/'} element={<Home/>}/>
                    <Route path={'/profile'} element={<Profile/>}/>
                    <Route path={'/streaming'} element={<Streaming/>}/>
                    {/*<Route path={'/success'} element={<Navigate to={'/'}/>}/>
                        <Route path={'/success-streaming'} element={<SuccessStreaming/>}/>*/}
                    <Route path={'/travel-info'} element={<TravelInfo/>}/>
                    <Route path={'/agenda/:day'} element={<DailyAgenda/>}/>
                    <Route path={'/event-contents/:day'} element={<EventContents/>}/>
                </Route>
            </Routes>

        </ThemeProvider>
    );
}

export default App;

import {createTheme, responsiveFontSizes} from "@mui/material";

let arenaTheme = createTheme({
    typography: {
        fontFamily: ['Walsheim']
    },

    components: {
        MuiCard: {
            variants: [
                {
                    props: {variant: 'login'},
                    style: {
                        width: '100%',
                        maxWidth:'37.5rem',
                        margin:'2rem auto',
                        height:'100%',
                        borderRadius: '2rem',
                        border: 0,
                        padding:'1rem 2rem 3rem 2rem',
                        backgroundColor: 'rgba(255,255,255,0.8)'
                    },
                },
            ]
        },
        MuiAppBar: {
            styleOverrides:{
                root:{
                    background:'rgba(13, 152, 216, 1)'
                }
            }
        },
        MuiLinearProgress: {
            styleOverrides:{
                root:{
                    width:'100% !important'
                }
            }
        }
    },

    palette: {
        mode: 'light',
        primary: {
            main: '#0d99d8',
        },
        /*
        secondary: {
            main: '#263238',
        },
        background: {
            default:'#131331',
        },
        accent: {
            main: '#e6fc69',
        },
        text: {
            primary: '#eceff1',
        },*/
    },
});

arenaTheme = responsiveFontSizes(arenaTheme)

export {arenaTheme}

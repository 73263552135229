import React, {useState} from "react";
import {Box, Button, Container, Stack, Typography, useMediaQuery, useTheme} from "@mui/material";
import HeaderLayout from "../Layouts/HeaderLayout";
import {Link, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import DaySwitch from "../components/DaySwitch";

const DailyAgenda = () => {
    const {day} = useParams()
    const {t} = useTranslation()
    const theme = useTheme()
    const matches = useMediaQuery(theme.breakpoints.up('sm'));

    return (
        <HeaderLayout>
            <Container sx={{mt: 10, mb: 2}}>
                <Stack direction={matches ? "row" : "column"} justifyContent={"space-between"} sx={{mb: 2}} spacing={2}>
                    <Button variant={"outlined"} component={Link} to={'/'}>{t('home_button')}</Button>
                    <Stack direction={matches ? "row" : "column"} spacing={2}>
                        <Button variant={day === '1' ? "contained" : "outlined"} component={Link} to={'/agenda/1'}>
                            DAY 1 - 9<sup style={{fontSize:'0.6rem'}}>th</sup>&nbsp;MAY
                        </Button>
                        <Button variant={day === '2' ? "contained" : "outlined"} component={Link} to={'/agenda/2'}>
                            DAY 2 - 10<sup style={{fontSize:'0.6rem'}}>th</sup>&nbsp;MAY
                        </Button>
                        <Button variant={day === '3' ? "contained" : "outlined"} component={Link} to={'/agenda/3'}>
                            DAY 3 - 11<sup style={{fontSize:'0.6rem'}}>th</sup>&nbsp;MAY
                        </Button>
                    </Stack>
                </Stack>

                <Typography variant={"h3"} textTransform={"uppercase"} textAlign={'center'} fontWeight={'bold'}>
                    {day === '1' ? <span>DAY 1<br/>9<sup style={{fontSize:'1.2rem', textTransform:'lowercase'}}>th</sup> MAY</span> : day === '2' ? <span>DAY 2<br/>10<sup style={{fontSize:'1.2rem', textTransform:'lowercase'}}>th</sup> MAY</span> : <span>DAY 3<br/>11<sup style={{fontSize:'1.2rem', textTransform:'lowercase'}}>th</sup> MAY</span>}
                </Typography>
                <Typography textAlign={'center'} style={{fontSize:'1.2rem', margin:0}}>{t('agenda.time_zone')}</Typography>
                <DaySwitch day={day}/>
            </Container>
        </HeaderLayout>
    )
}

export default DailyAgenda

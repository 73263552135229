import React, {useEffect, useState} from "react";
import Header from "../components/Header";
import UserService from "../services/user.service";
import {Backdrop, CircularProgress, Container, FormControl, TextField, Typography, useTheme} from "@mui/material";
import HeaderLayout from "../Layouts/HeaderLayout";
import {useTranslation} from "react-i18next";
import {dataMap} from "../assets/data/dataMap";
import {StepComponent} from "../components/registration/StepComponent";

const Profile = () => {
    const {t} = useTranslation()
    const [loading, setLoading] = useState(true)
    const [userData, setUserData] = React.useState([])
    const theme = useTheme()

    useEffect(async () => {
        await fetchData()
        setLoading(false)
    }, [])

    async function fetchData() {
        const response = await UserService.getUserData()
        setUserData(response.data.steps)
    }

    return(
        <HeaderLayout>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Container sx={{mb:5, mt:10}}>
                {userData.map(d =>
                    <StepComponent step={dataMap[d.step]} userData={d} handleCompileData={''} profile={true}/>
                )}
            </Container>
        </HeaderLayout>

    )
}

export default Profile

import React, {useEffect, useState} from "react";
import HeaderLayout from "../Layouts/HeaderLayout";
import {Box, Container, Paper, Stack, Typography, useTheme} from "@mui/material";
import Footer from "../components/Footer";
import videojs from 'video.js';
import axios from "axios";
import authHeader from "../services/auth-header";
import VideoJS from "../components/VideoJS";
import {API_URL} from "../config";

const Streaming = () => {

    const [message, setMessage] = useState('');
    const [backupPlayer, setBackupPlayer] = useState('');
    const theme = useTheme();
    useEffect(() =>{
        axios.post(API_URL+'start-streaming', {}, {headers: authHeader()}).then((res) => {
            setMessage(res.data.message)
            setBackupPlayer(res.data.backupPlayer)
        })
    }, [])

    return(
      <HeaderLayout>
          <main className={'travelInfo'}>
              <Footer/>
              <Container sx={{pt:10, pb:7}}>
                  <div style={{position: 'relative', paddingBottom: '56.25%', height: 0, overflow: 'hidden', width:'100%'}} >
                      {/*<div style={{
                          width: '100%',
                          height: '100%',
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          border: "none",
                          background: 'black',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center'
                      }}>
                          <p style={{
                              color: "white",
                              fontSize: 'min(7rem,7vw)',
                              textTransform: 'uppercase',
                              fontWeight: 'bold'
                          }}>Coming soon</p>
                      </div>*/}
                      <Box sx={{width: '100%'}}>
                          <div style={{padding: '56.25% 0 0 0', position: 'relative'}}>
                              <iframe
                                  src={"https://players.plesh.co/arena"}
                                  frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen
                                  style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%'}}
                              ></iframe>
                          </div>
                      </Box>
                  </div>
                  {backupPlayer !== '' && <div style={{borderRadius: 0, color:'white'}}>
                      <span style={{background: theme.palette.primary.main, mt: 2, width: 'auto', fontSize:'1.1rem', padding:'0.5rem'}}>If you have any problem with the player, <a style={{color:'white'}} href={backupPlayer}>click here</a></span>
                  </div>}
                  {message && message.length > 0 && <Stack direction={"column"} alignItems={"center"} justifyContent={"center"}>
                      <Paper variant={"outlined"} sx={{borderRadius: 0, background: theme.palette.primary.main, mt: 2, width: 'auto'}}>
                          <Typography variant={"h4"} sx={{color: 'white', py: 1, px: 2}} textAlign={"center"}>
                              {message}
                          </Typography>
                      </Paper>
                  </Stack>}
              </Container>
          </main>
      </HeaderLayout>
  )
}

export default Streaming

import React, {useEffect, useState} from "react";
import Header from "../components/Header";
import {Backdrop, Box, CircularProgress, Dialog} from "@mui/material";
import {useMotionValue} from "framer-motion";
import UserService from "../services/user.service";
import StreamingPrivacyDialog from "../components/StreamingPrivacyDialog";

const HeaderLayout = ({children}) => {
    const [hash, setHash] = React.useState('')
    const [loading, setLoading] = useState(true)
    const [complete, setComplete] = useState(true)
    const [streaming, setStreaming] = useState(false)

    useEffect(async () => {
        setLoading(true)
        await fetchData()
        setLoading(false)
    }, [])

    async function fetchData() {
        const response = await UserService.getUserData()
        console.log(response.data)
        setHash(response.data.hash)
        setComplete(response.data.complete)
        setStreaming(response.data.streaming ?? false)
    }

    return (
        <>
            <Backdrop open={loading}>
                <CircularProgress/>
            </Backdrop>
            <Header hash={hash} complete={complete} loading={loading} streaming={streaming} loading={loading}/>
            <Box>{children}</Box>
        </>
    )
}

export default HeaderLayout

import React from "react";
import {Box} from "@mui/material";
import {useTranslation} from "react-i18next";

const Footer = () => {
    const {t} =useTranslation()
  return(
      <Box style={{color:'white', textAlign:'center'}} sx={{mt:2, display: {sm:'block', md:'none'}}}>
          <p style={{fontSize:'min(3rem,5vw)', textTransform:'uppercase', margin:0, lineHeight:'2rem'}}><b>SS24</b> {t('home.title2')}</p>
          {/*<div style={{borderLeft: '10px solid #0d99d8', margin: '2vw 4rem'}}>
              <p style={{
                  border: '1px solid white',
                  borderLeft: '0',
                  textTransform: 'uppercase',
                  fontFamily: 'Walsheim',
                  fontWeight: '700',
                  fontSize: 'min(3rem, 6vw)',
                  margin: 0
              }}>it's in our nature.</p>
          </div>*/}
      </Box>
  )
}

export default Footer

import React, {useState} from "react";
import {
    Checkbox,
    FormControl,
    FormControlLabel, FormGroup, FormHelperText, FormLabel,
    Grid,
    InputLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select, TextareaAutosize,
    TextField, Typography, useTheme
} from "@mui/material";
import {
    DatePicker,
    DateTimePicker,
    DesktopDatePicker,
    LocalizationProvider,
    MobileDatePicker
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {Trans, useTranslation} from "react-i18next";
import dayjs from "dayjs";
import {validateDate} from "@mui/x-date-pickers/internals";

export const StepContent = ({userData, handleCompileData, c, type, detail, profile}) => {
    const {t} = useTranslation()
    const [other, setOther] = useState('Other')

    switch (c.type) {
        case 'note':
            return (
                <Grid item xs={12}>
                    <Typography variant={'h6'} fontWeight={'bold'}>
                        {t(c.label)}
                    </Typography>
                </Grid>
            )
        case 'select':
            return (
                <Grid item xs={12} md={6}>
                    <FormControl key={c.id} sx={{
                        width: '100%',
                    }} variant={"standard"} required={c.required}>
                        <InputLabel id="demo-simple-select-label">{t(c.title)}</InputLabel>
                        <Select
                            disabled={profile}
                            id="demo-simple-select"
                            value={type ? userData.values[type][c.id] : userData.values[c.id]}
                            label={t(c.title)}
                            onChange={(e) => handleCompileData(c.id, e.target.value, type)}
                        >
                            {c.fields.map(f => <MenuItem value={f.value}>{t(f.label)}</MenuItem>)}
                        </Select>
                    </FormControl>
                </Grid>
            )
        case 'datetimepicker':
            return (
                <Grid item xs={12} md={6}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DateTimePicker
                            maxDate={c.maxDate ? new Date(c.maxDate) : null}
                            minDate={c.minDate ? new Date(c.minDate) : null}
                            disabled={profile}
                            label={t(c.title)}
                            required={c.required}
                            inputFormat="dd/MM/yyyy hh:mm a"
                            defaultCalendarMonth={c.maxDate ? new Date(c.maxDate) : null}
                            value={type ? (userData.values[type][c.id] ? userData.values[type][c.id] : null) : (userData.values[c.id] ? userData.values[c.id] : null)}
                            onChange={(e) => handleCompileData(c.id, e, type)}
                            renderInput={(params) =>
                                <TextField {...params}
                                           required={c.required}
                                           variant={'standard'}/>}
                        />
                    </LocalizationProvider>
                </Grid>
            )
        case 'datepicker':
            return (
                <Grid item xs={12} md={6}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DesktopDatePicker
                                maxDate={c.maxDate ? new Date(c.maxDate) : null}
                                minDate={c.minDate ? new Date(c.minDate) : null}
                                disabled={profile}
                                label={t(c.title)}
                                inputFormat="dd/MM/yyyy"
                                required
                                defaultCalendarMonth={c.maxDate ? new Date(c.maxDate) : null}
                                value={type ? userData.values[type][c.id] : userData.values[c.id]}
                                renderInput={(params) =>
                                    <TextField {...params} required
                                               variant={'standard'} sx={{width:'100%'}}/>}
                                onChange={(e) => {handleCompileData(c.id, e, type)}}/>
                        </LocalizationProvider>
                </Grid>
            )
        case 'radiogroup':
            return (
                <Grid item xs={12} md={12}>
                    <FormControl required={c.required} key={c.id} sx={{
                        width: '100%'
                    }}>
                        {c.label && <FormLabel><Trans i18nKey={c.label} components={{1: <a href={'/privacy_policy_en.pdf'} target={'_blank'}/>}}/></FormLabel>}
                        {c.helper && <FormHelperText sx={{fontSize:'1rem', marginLeft:0}}><Trans i18nKey={c.helper}/></FormHelperText>}
                        <RadioGroup
                            row={!c.direction}
                            name={c.id}
                            value={type ? (userData.values[type][c.id] ? userData.values[type][c.id] : null) : (userData.values[c.id] ? userData.values[c.id] : null)}
                            onChange={(e) => handleCompileData(c.id, e.target.value, type)}
                        >
                            {
                                c.fields.map(v => {
                                        return (
                                            <FormControlLabel value={v.value} control={<Radio disabled={profile} required={c.required}/>}
                                                              label={t(v.label)}/>)
                                    }
                                )
                            }
                            {c.other &&
                                <FormControlLabel
                                    value={other}
                                    checked={!c.fields.map(f => f.value).includes(userData.values[c.id]) && userData.values[c.id] !== ''}
                                    control={<Radio/>} label={<TextField
                                    value={(!c.fields.map(f => f.value).includes(userData.values[c.id])) ? userData.values[c.id] :''}
                                    onChange={(e) => {
                                        handleCompileData(c.id, e.target.value)
                                        setOther(e.target.value)
                                    }}
                                    variant={"standard"} placeholder={'Other:'}/>}/>
                            }
                        </RadioGroup>
                    </FormControl>
                </Grid>
            )
        case 'checkbox':
            return <Grid item xs={12}>
                <FormControl fullWidth sx={{pt:2}}>
                    <FormGroup>
                        <FormControlLabel
                            sx={{alignItems:'start'}}
                            control={<Checkbox checked={userData.values[c.id]}
                                               sx={{pt:0}}
                                               required={c.required}
                                               onChange={(e) => handleCompileData(c.id, e.target.checked)}
                            />} label={<span><Trans i18nKey={c.label} components={{1: <a href={'/privacy_policy_en.pdf'} target={'_blank'}/>}}/></span>}/>
                    </FormGroup>
                </FormControl>
            </Grid>
        case 'textbox':
            return (
                <Grid item xs={12}>
                    <FormControl key={c.id} sx={{
                        width: '100%'
                    }}>
                        <Typography id="demo-simple-select-standard-label">{t(c.title)}</Typography>
                        <TextareaAutosize
                            disabled={profile}
                            required={c.required}
                            aria-label="minimum height"
                            minRows={3}
                            value={type ? userData.values[type][c.id] : userData.values[c.id]}
                            style={{width: '100%', fontFamily: 'inherit', resize: 'none'}}
                            onChange={(e) => handleCompileData(c.id, e.target.value, type)}
                        />
                    </FormControl>
                </Grid>
            )
        default:
            return (
                <Grid item xs={12} md={6}>
                    <TextField disabled={c.compiled || profile} placeholder={c.placeholder ? c.placeholder : ''}
                               required={c.required}
                               value={type ? (type === 'plane' ? (userData.values[detail.id][type][c.id] ? userData.values[detail.id][type][c.id] : '') : (userData.values[type][c.id] ? userData.values[type][c.id] : '')) : (userData.values[c.id] ? userData.values[c.id] : '')}
                               onChange={(e) => handleCompileData(c.id, e.target.value, type, detail)}
                               sx={{width: '100%'}} variant={"standard"} type={c.type}
                               label={t(c.title)}></TextField>
                </Grid>
            )

    }
}

import React, {useState} from "react";
import {Box, FormControl, Grid, InputLabel, MenuItem, Select, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import ColleagueForm from "./ColleagueForm";
import FriendsForm from "./FriendsForm";
import {StepContent} from "../StepContent";

const RoommatesForm = ({userData, handleCompileData, step, profile}) => {
    const {t, i18n} = useTranslation()
    return (
        <Box>
            <FormControl sx={{width: 200}}>
                <InputLabel id="demo-simple-select-label">{t('step4.plus1')}</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    disabled={profile}
                    id="demo-simple-select"
                    value={userData.values.roommates.type}
                    label={t('step4.plus1')}
                    onChange={(e) => handleCompileData('type', e.target.value, 'roommates' )}
                    variant={"standard"}
                >
                    <MenuItem value={'colleague'}>{t('step4.colleague')}</MenuItem>
                    <MenuItem value={'friends'}>{t('step4.friends')}</MenuItem>
                </Select>
            </FormControl>
            {userData.values.roommates.type !== '' &&
                <Box>
                    <Grid container spacing={3}>
                        {step.roommates.map((c, i) => {
                            if (c.id !== 'type') {
                                if (userData.values.roommates.type === 'colleague' && i<3){
                                    return <StepContent profile={profile} handleCompileData={handleCompileData} userData={userData} c={c}
                                                 type={'roommates'}/>
                                } else if(userData.values.roommates.type === 'colleague' && i>=3) {
                                    return ''
                                } else {
                                    return <StepContent profile={profile} handleCompileData={handleCompileData} userData={userData} c={c}
                                                 type={'roommates'}/>
                                }
                            } else {
                                return ''
                            }
                        })
                        }
                    </Grid>
                    {userData.values.roommates.type === 'colleague' && <Typography sx={{mt: 2}}>{t('step4.colleague_separately')}</Typography>}
                </Box>
            }
        </Box>
    )
}

export default RoommatesForm

import React, {useState} from "react";
import {Box, Button, Collapse, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {VideoPresentation} from "./VideoPresentation";
import axios from "axios";
import authHeader from "../services/auth-header";
import {API_URL} from "../config";

export const DayContents = ({day, index}) => {
    const {t} = useTranslation()
    const [open, setOpen] = useState(false)

    const doDownload = (id) => {
        axios.get(API_URL+'event-contents/'+id,{ headers: authHeader() }).then()
    }

    return(
        <Box>
            {index!== 0 && <Button sx={{width: '20rem'}} variant={'contained'} size={'large'}
                     onClick={() => setOpen(!open)}>{t('content.presentations')}</Button>}
            <Collapse in={open}>
                <ul>
                    {day.filter(p => p.pdf !==null).map(p =>
                        <li>
                            <Typography variant={'h6'}>{p.name}{p.ppt!==null &&
                                <Button onClick={() => doDownload(p.id)} href={p.ppt} target={'_blank'}>PPT</Button>}{p.pdf!==null &&
                                <Button href={p.pdf} target={'_blank'}>PDF</Button>}</Typography>
                        </li>
                    )}
                </ul>
            </Collapse>
            <Typography variant={"h5"} sx={{mt: 3}} fontWeight={"bold"}>
                {t('content.live')}
            </Typography>
            {day.map(presentation =>
                <VideoPresentation doDownload={doDownload} presentation={presentation}/>
            )}
        </Box>
    )
}

import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import logo from '../assets/images/logo_white.png'
import {Link, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Stack, useTheme} from "@mui/material";
import authService from "../services/auth.service";
import {Logout} from "@mui/icons-material";
import {useEffect, useMemo, useState} from "react";
import UserService from "../services/user.service";

const Header = ({hash, complete, loading, streaming}) => {
    const {t} = useTranslation()
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const navigate = useNavigate()

    const pages = useMemo(() => filterPages(), [complete, loading, streaming])

    function filterPages() {
        let p = [
            {id: 'home', title: t('header.home'), link: '/'},
            {id: 'profile', title: t('header.profile'), link: '/profile'},
            /*{id: 'streaming', title: t('header.streaming'), link: '/streaming'},
            {id: 'travel', title: t('header.travel'), link: '/travel-info'},*/
            {title: t('header.contents'), link:'/event-contents/1'}]

        if(!loading){
            if(streaming){
                return p.filter(p => p.id !== "profile" && p.id !== "travel")
            } else {
                if (!complete) {
                    return p.filter(p => p.id !== 'profile')
                } else {
                    return p
                }

            }
        }

        return []



    }


    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    return (
        <AppBar position="fixed">
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <Box
                        component={Link}
                        to={'/'}
                        sx={{mr: 2, display: {xs: 'none', lg: 'flex'}}}
                    >
                        <img src={logo} style={{width: '100%', maxWidth: '70px'}}/>
                    </Box>
                    <Box sx={{display: {xs: 'none', lg: 'block'}, flexGrow: 1}}>
                        <p style={{fontSize: 'min(1.3rem, 1.5vw)', textTransform: 'uppercase', paddingRight: '3rem'}}>
                            <b>SS24</b> Global Sales Meeting</p>
                    </Box>
                    <Box sx={{display: {xs: 'flex', lg: 'none'}}}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon/>
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: {xs: 'block', lg: 'none'},
                            }}
                        >
                            {pages.map((page) => (
                                <MenuItem key={page.title} onClick={handleCloseNavMenu}
                                          to={page.link}
                                          component={Link}>
                                    <Typography textAlign="center">{page.title}</Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                    <Box
                        component={Link}
                        to={'/'}
                        sx={{
                            flexGrow: 1,
                            display: {xs: 'flex', lg: 'none'},
                            justifyContent: {xs: 'center', lg: 'start'}
                        }}
                    >
                        <img src={logo} style={{width: '100%', maxWidth: '50px'}}/>
                    </Box>
                    <Stack sx={{flexGrow: 0, display: {xs: 'none', lg: 'flex'}, textAlign: 'center', mr: 3}} spacing={3}
                           direction={'row'} alignItems={'center'}>
                        {pages.map((page) => (
                            <Button
                                key={page.title}
                                onClick={handleCloseNavMenu}
                                to={page.id === 'compensation' ? page.link + hash : page.link}
                                target={page.id === 'compensation' ? '_blank' : '_self'}
                                component={Link}
                                sx={{my: 2, color: 'white', display: 'block'}}
                            >
                                {page.title}
                            </Button>
                        ))}
                    </Stack>
                    <IconButton sx={{color: 'white'}} onClick={() => {
                        authService.logout()
                        navigate('/login', {replace: true})
                    }}>
                        <Logout/>
                    </IconButton>
                </Toolbar>
            </Container>
        </AppBar>
    );
};
export default Header;

import axios from "axios";
import authHeader from "./auth-header";
import {API_URL} from "../config";

const getPublicContent = () => {
    return axios.get(API_URL + "all");
};
const getUserData = () => {
    return axios.get(API_URL + "user", { headers: authHeader() })
};

const UserService = {
    getPublicContent,
    getUserData
};

export default UserService;

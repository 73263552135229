import React from "react";
import {Box, Button, Container, Typography} from "@mui/material";
import {motion, useMotionValue} from "framer-motion";
import SuccessCheck from "../components/SuccessCheck";
import logo from "../assets/images/logo.png";
import {useTranslation} from "react-i18next";

const ThankYouStreaming = () => {
    const {t, i18n} = useTranslation()
    let progress = useMotionValue(90)

    return (
        <React.Fragment>
            <div style={{textAlign: 'center'}}>
                <Typography variant={'h4'} sx={{mt: 2, mb: 4}} textAlign={'center'}>
                    {t('thank_you_streaming')}
                </Typography>
                <div style={{width: 'max(20rem,20%)', margin: '4rem auto', textAlign: 'center'}}>
                    {/* eslint-disable-next-line react/jsx-no-undef */}
                    <motion.div
                        initial={{x: 0}}
                        animate={{x: 100}}
                        style={{x: progress}}
                        transition={{duration: 0.8}}
                    />
                    <SuccessCheck progress={progress}/>
                </div>
                <Box sx={{display: 'flex', flexDirection: 'row', pt: 2}}>
                    <Box sx={{flex: '1 1 auto'}}/>
                </Box>
            </div>

        </React.Fragment>
    )
}

export default ThankYouStreaming

import React, {useState, Suspense} from "react";
import {Link, useNavigate} from 'react-router-dom'
import {Box, Button, Card, CardContent, CircularProgress, Container, Stack, TextField, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import logo from '../assets/images/logo.png'
import claim from '../assets/images/claim.png'
import authService from "../services/auth.service";

const Login = () => {
    let navigate = useNavigate()
    const {t, i18n} = useTranslation()
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [message, setMessage] = useState('')
    const [loading, setLoading] = useState(false)

    const handleSetEmail = (e) => {
        setEmail(e.target.value)
    }

    const handleSetPassword = (e) => {
        setPassword(e.target.value)
    }

    const handleLogin = (e) => {
        e.preventDefault();
        setMessage("");
        setLoading(true);
        authService.login(email, password).then(
            response => {
                navigate(response.complete ? "/" : "/register");
                window.location.reload();
            },
            (error) => {
                const resMessage = t('wrong_login')
                setLoading(false);
                setMessage(resMessage);
            }
        )
    }

    return (
        <main style={{width: '100%', padding: '8rem 0',}}>
            <Container sx={{textAlign: 'center'}}>
                <Box sx={{mb: 5}}>
                    <img src={logo}/>
                </Box>
                <Card style={{height: '100%', textAlign: 'right'}} variant={'login'}>
                    <CardContent>
                        <Stack direction={"column"} alignItems={"center"} textAlign={'center'}>
                            <Typography variant={'h4'}>{t('login.title')}</Typography>
                            <TextField style={{maxWidth: '31.25rem'}} sx={{my: 2}} fullWidth placeholder={'Email'}
                                       onChange={handleSetEmail}/>
                            <TextField style={{maxWidth: '31.25rem'}} sx={{mb: 2}} fullWidth
                                       placeholder={t('login.password')} type={"password"}
                                       onChange={handleSetPassword}/>
                            {loading ? <Box sx={{textAlign:'center'}}><CircularProgress/></Box> : <Button onClick={handleLogin} variant={"contained"} color={"primary"} fullWidth style={{
                                maxWidth: '18.75rem',
                                height: '3rem',
                                fontSize: '1.2rem'
                            }}>{t('login.login')}</Button>}
                            {message !== '' && <Typography sx={{mt: 2}} color={'red'}>{message}</Typography>}
                            <Link to={'/recover-password'} target={'_blank'}
                                  style={{marginTop: '1rem', fontSize:'1.3rem', marginBottom:0}}>{t('login.recover')}</Link>
                        </Stack>
                    </CardContent>
                </Card>

            </Container>
        </main>
    )
}

export default Login

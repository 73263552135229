import React, {useState} from "react";
import {Backdrop, Box} from "@mui/material";
import Day1 from "./Day1";
import Day2 from "./Day2";
import Day3 from "./Day3";
import IconButton from "@mui/material/IconButton";
import {Close} from "@mui/icons-material";


const DaySwitch = ({day}) => {
    const [open, setOpen] = useState(false)
    const [content, setContent] = useState('')

    const handleClose = () => {

        setOpen(false);
    };

    const handleToggle = (img) => {
        setContent(img)
        setOpen(!open);
    };

    const BackdropContent = () => {
        return(
            <Box style={{width:'100%', maxWidth:'60rem', display:"flex", justifyContent:'center', position:'relative'}}>
                <IconButton style={{position:'absolute', right:0, fontSize:'4rem', color:"white"}} sx={{mt:-6, mr: {md:-6, xs:0}}}>
                    <Close fontSize={"inherit"}/>
                </IconButton>
                <img style={{width:'100%', padding:'1rem'}} src={require(`../assets/images/${content}.png`)}/>
            </Box>
        )
    }

    const DayRender = ({handleToggle}) => {
        switch(day) {
            case '1':
                return <Day1/>;
            case '2':
                return <Day2/>;
            case '3':
                return <Day3/>;
            default:
                return 'ops';
        }
    }

    return (
        <Box>
            <Backdrop
                sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                open={open}
                onClick={handleClose}
            >
                {content!=='' && <BackdropContent/>}
            </Backdrop>
            <DayRender handleToggle={handleToggle}/>
        </Box>
    )
}

export default DaySwitch

import React, {useState} from "react";
import {Box, Button, Card, CircularProgress, Container, Stack, TextField, Typography} from "@mui/material";
import logo from "../assets/images/logo.png";
import {Link} from "react-router-dom";
import claim from "../assets/images/claim.png";
import {Trans, useTranslation} from "react-i18next";
import axios from "axios";
import {ArrowLeft} from "@mui/icons-material";
import {API_URL} from "../config";

const Recover = () => {
    const {t, i18n} = useTranslation()
    const [email, setEmail] = useState('')
    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(false)

    const handleSetEmail = (e) => {
        setEmail(e.target.value)
    }

    const handleReset = async () => {
        setLoading(true)
        setSuccess(false)
        setError(false)
        await axios.post(API_URL+'recover-password', {
            email: email,
            language: i18n.language
        })
            .then(response => setSuccess(true))
            .catch(error => setError(true))
        setLoading(false)
    }

    return (
        <main style={{width: '100%', padding: '8rem 0',}}>
            <Container sx={{textAlign: 'center', paddingTop: '2rem'}}>
                <img src={logo}/>
                <Card style={{height: '100%', textAlign: 'center'}} variant={'login'}>
                    <Stack direction={"row"} justifyContent={'space-between'} style={{width: '100%', maxWidth: '31.25rem', margin: 'auto', paddingBottom: '2rem'}}>
                        <Button startIcon={<ArrowLeft/>} variant={"outlined"} component={Link} to={'/login'}>{t('recover.back')}</Button>
                    </Stack>
                    {!loading &&
                        <Stack direction={"column"} alignItems={"center"} textAlign={'center'}>
                            <Typography variant={'h5'}>{t('recover.title')}</Typography>
                            <TextField style={{maxWidth: '31.25rem'}} sx={{my: 2}} fullWidth
                                       placeholder={t('recover.email')}
                                       onChange={handleSetEmail}/>
                            <Button onClick={handleReset} variant={"contained"} color={"primary"} fullWidth style={{
                                maxWidth: '18.75rem',
                                height: '3rem',
                                fontSize: '1.2rem'
                            }}>{t('recover.button')}</Button>
                            {success && <Typography sx={{mt: 2}} color={'green'}>{t('recover.success')}</Typography>}
                            {error &&
                                <Typography sx={{mt: 2}} color={'red'}>
                                    <Trans i18nKey={'recover.error1'}/>
                                    <a href='mailto:info@globalsalesmeeting.arenasport.com' target='_blank'>info@globalsalesmeeting.arenasport.com</a>
                                    <Trans i18nKey={'recover.error3'}/>
                                </Typography>}
                        </Stack>}
                    {loading &&
                        <Box style={{width: '100%', textAlign: 'center'}}>
                            <CircularProgress/>
                        </Box>}
                </Card>
                {/*<img src={claim} style={{width: '100%', maxWidth: '37.5rem'}}/>*/}
            </Container>
        </main>
    )
}

export default Recover

import React from "react";
import {Box} from "@mui/material";
import {useTranslation} from "react-i18next";

const Day3 = ({handleToggle}) => {
    const {t} = useTranslation()
    const day = 'agenda.day3.'

    return (
        <Box>
            <p style={{fontSize: 'min(1.38rem)'}}>
                from 9.00 to 12.00 AM - Touch and Feel
                <br/>
                <b><i>Palacio de Congresos - First Floor</i></b>
            </p>
        </Box>
    )
}

export default Day3

export const dataMap = [
    {
        id: 0,
        titolo: '',
        campi: [
            {
                id: 'participatePerson', type: 'select', title: 'step0.participatePerson', fields: [
                    {value: "yes", label: 'Yes'},
                    {value: "no", label: 'No'}
                ], compiled: false, required: true
            }
        ]
    },
    {
        id: 1,
        titolo: '',
        campi: [
            {
                id: 'i_am', type: 'select', title: 'step0.i_am', fields: [
                    {value: "distributor", label: 'step0.distributor'},
                    {value: "employee", label: 'step0.employee'}
                ], compiled: false, required: true
            }
        ]
    },
    {
        id: 2,
        titolo: 'step1.title',
        campi: [
            {id: 'name', title: 'step1.name', compiled: true, required: true},
            {id: 'surname', title: 'step1.surname', compiled: true, required: true},
            {id: 'street', title: 'step1.address', compiled: false, required: true},
            {id: 'zip_code', title: 'step1.zipcode', compiled: false, required: true},
            {id: 'district', title: 'step1.district', compiled: false, required: false},
            {id: 'town', title: 'step1.town', compiled: false, required: true},
            {id: 'country', title: 'step1.country', compiled: false, required: true},
            {id: 'place_of_birth', title: 'step1.placeBirth', compiled: false, required: true},
            {id: 'date_of_birth', title: 'step1.dateBirth', compiled: false, required: true, type: 'datepicker'},
            {id: 'nationality', title: 'step1.nationality', compiled: false, required: true},
        ]
    },
    {
        id: 3,
        titolo: "step2.title",
        campi: [
            {
                id: 'doc_type', type: 'select', title: 'step2.docType', fields: [
                    {value: "cartaIdentita", label: 'step2.identityCard'},
                    {value: "passaporto", label: 'step2.passport'}
                ], compiled: false, required: true
            },
            {id: 'doc_number', title: 'step2.docNumber', compiled: false, required: true},
            {id: 'issued_on', type: 'datepicker', title: 'step2.issuedOn', compiled: false, required: true},
            {id: 'issued_in', title: 'step2.issuedIn', compiled: false, required: true},
            {id: 'expiry_date', type: 'datepicker', title: 'step2.expiryDate', compiled: false, required: true},
        ]
    },
    {
        id: 4,
        titolo: "step3.title",
        campi: [
            {id: 'email', title: 'step3.email', compiled: true, required: true},
            {id: 'secondary_email', title: 'step3.secondaryEmail', compiled: false, required: false},
            {id: 'mobile', title: 'step3.mobile', compiled: false, required: true},
        ]
    },
    {
        id: 5,
        titolo: "step4.title",
        subtitle: "step4.subtitle1",
        campi: [
            {
                id: 'in_date',
                title: 'step4.arrive',
                compiled: false,
                required: true,
                type: 'datepicker',
                minDate: "5/1/2023",
                maxDate: "5/31/2023"
            },
            {
                id: 'out_date',
                title: 'step4.departure',
                compiled: false,
                required: true,
                type: 'datepicker',
                minDate: "5/1/2023",
                maxDate: "5/31/2023"
            },
            {
                id:'dietary_requirements', label:"step4.dietary", compiled: false, required: true, type: 'radiogroup',
                fields: [
                    {value: "yes", label: 'Yes'},
                    {value: "no", label: 'No'}
                ]
            },
            {id: 'dietary_specific', title: 'step4.dietarySpecific', compiled: false, required: true, type: 'textbox',
                conditions: {
                    predicates:[
                        {
                            id:"dietary_requirements",
                            operator:"equal",
                            value:"yes"
                        }
                    ]
                }},
            {
                id: 'bed', fields: [
                    {value: "alone", label: 'step4.bed1'},
                    {value: "king", label: 'step4.bed2'},
                    {value: "twin", label: 'step4.bed3'}
                ], compiled: false, required: true, type: 'radiogroup'
            },
        ],
        roommates: [
            {id: 'type', title: 'step4.plus1', value: '', compiled: false, required: true},
            {id: 'name', title: 'step1.name', value: '', compiled: false, required: true},
            {id: 'surname', title: 'step1.surname', value: '', compiled: false, required: true},
            {id: 'street', title: 'step1.address', value: '', compiled: false, required: true},
            {id: 'zip_code', title: 'step1.zipcode', value: '', compiled: false, required: true},
            {id: 'district', title: 'step1.district', value: '', compiled: false, required: false},
            {id: 'town', title: 'step1.town', value: '', compiled: false, required: true},
            {id: 'country', title: 'step1.country', value: '', compiled: false, required: true},
            {id: 'place_of_birth', title: 'step1.placeBirth', value: '', compiled: false, required: true},
            {
                id: 'date_of_birth',
                title: 'step1.dateBirth',
                value: '',
                compiled: false,
                required: true,
                type: 'datepicker'
            },
            {id: 'nationality', title: 'step1.nationality', value: '', compiled: false, required: true},
            {
                id: 'doc_type', title: 'step2.docType', value: '', type: 'select', fields: [
                    {value: "cartaIdentita", label: 'step2.identityCard'},
                    {value: "passaporto", label: 'step2.passport'}
                ], compiled: false, required: true
            },
            {id: 'doc_number', title: 'step2.docNumber', value: '', compiled: false, required: true},
            {
                id: 'issued_on',
                title: 'step2.issuedOn',
                value: null,
                compiled: false,
                required: true,
                type: 'datepicker'
            },
            {id: 'issued_in', title: 'step2.issuedIn', value: '', compiled: false, required: true},
            {
                id: 'expiry_date',
                title: 'step2.expiryDate',
                value: null,
                compiled: false,
                required: true,
                type: 'datepicker'
            },
            {
                id: 'dietary_requirements',
                title: 'step4.dietary',
                value: '',
                compiled: false,
                required: false,
                type: 'textbox'
            },
        ]
    },
    {
        id: 6,
        titolo: 'step5.title',
        subtitle:"step5.subtitle",
        campi: [
            {id: 'note-1', label:"step5.note-1", type:'note'},
            {
                id: 'fromCountry', title:"step5.fromCountry", compiled: false, required: true, type: 'text'
            },
            {
                id: 'fromCity', title:"step5.fromCity", compiled: false, required: true, type: 'text'
            },
            {id: 'note-5', label:"step5.transportFirstStep", type:'note'},
            {
                id: 'transportFirstStep', label:'step5.firstStepLabel', fields: [
                    {value: "privateCar", label: 'step5.privateCar'},
                    {value: "taxi", label: 'step5.taxi'},
                    {value: "train", label: 'step5.train'},
                    {value: "bus", label: 'step5.bus'},
                ], compiled: false, required: true, type: 'radiogroup', other:true, direction:'column'
            },
            {id: 'note-2', label:"step5.note-2", type:'note'},
            {id: 'note-3', label:"step5.note-3", type:'note'},
            {
                id:'arrivalDateTime', title:"step5.arrivalDateTime", compiled: false, required: true, type: 'datetimepicker'
            },
            {
                id: "arrivalTransport", title:"", fields: [
                    {value: "ferry", label: 'step5.byFerry'},
                    {value: "plane", label: 'step5.byPlane'},
                ], compiled: false, required: true, type: 'radiogroup',
            },
            {
                id: 'arrivalFromAirport', title:"step5.departureAirport", compiled: false, required: true, type: 'text',
                conditions: {
                    predicates:[
                        {
                            id:"arrivalTransport",
                            operator:"equal",
                            value:"plane"
                        }
                    ]
                }
            },
            {
                id: 'arrivalToAirport', title:"step5.arrivalAirport", compiled: false, required: true, type: 'text',
                conditions: {
                    predicates:[
                        {
                            id:"arrivalTransport",
                            operator:"equal",
                            value:"plane"
                        }
                    ]
                }
            },
            {id: 'note-4', label:"step5.note-4", type:'note'},
            {
                id:'departureDateTime', title:"step5.departureDateTime", compiled: false, required: true, type: 'datetimepicker'
            },
            {
                id: "departureTransport", title:"", fields: [
                    {value: "ferry", label: 'step5.byFerry'},
                    {value: "plane", label: 'step5.byPlane'},
                ], compiled: false, required: true, type: 'radiogroup',
            },
            {
                id: 'departureFromAirport', title:"step5.departureAirport", compiled: false, required: true, type: 'text',
                conditions: {
                    predicates:[
                        {
                            id:"departureTransport",
                            operator:"equal",
                            value:"plane"
                        }
                    ]
                }
            },
            {
                id: 'departureToAirport', title:"step5.arrivalAirport", compiled: false, required: true, type: 'text',
                conditions: {
                    predicates:[
                        {
                            id:"departureTransport",
                            operator:"equal",
                            value:"plane"
                        }
                    ]
                }
            },
            {id: 'note-6', label:"step5.note-6", type:'note'},
            {
                id: 'toHotel', title:"step5.toHotel", fields: [
                    {value: "rentalCar", label: 'step5.rentalCar'},
                    {value: "taxi", label: 'step5.taxi'},
                    {value: "bus", label: 'step5.bus'},
                ], compiled: false, required: true, type: 'radiogroup', other:true, direction:'column'
            },
        ]
    },
    {
        id: 7,
        titolo: 'step7.title',
        campi: [
            {
                id: "privacy_1",
                label: 'step7.part1',
                value: null,
                required: true,
                type: 'radiogroup',
                fields: [
                    {value: "yes", label: 'I consent'},
                    {value: "no", label: 'I do not consent'}
                ]
            },
            {
                id: "privacy_2", label: 'step7.part2', value: null, required: true, type: 'radiogroup',
                helper:"step7.part2-helper",
                fields: [
                    {value: "yes", label: 'I consent'},
                    {value: "no", label: 'I do not consent'}
                ]
            },
            {
                id: "privacy_3", label: 'step7.part3', value: null, required: true, type: 'radiogroup',
                fields: [
                    {value: "yes", label: 'I consent'},
                    {value: "no", label: 'I do not consent'}
                ]
            }
        ]
    },

]

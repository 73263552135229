import React, {useState} from 'react'
import {Backdrop, Box, CircularProgress} from "@mui/material";
import {useTranslation} from "react-i18next";

const Day1 = ({handleToggle}) => {
    const {t} = useTranslation()

    return (
        <Box>
            <p style={{fontSize: 'min(1.38rem)'}}>
                5.30 PM - Rendezvous at dedicated desk at <b><i>your Hotel</i></b>
                <br/>
                6.00 PM - Departure to <b><i>Palacio de Congresos</i></b> (by foot - 20 mins walk)
                <br/>
                6.20 PM - Start of Brand Experience
                <br/>
                <b><i>Palacio de Congresos - First Floor</i></b>
                <br/>
                <br/>
                from 6.45 to 7.45 PM - Welcome drink
                <br/>
                <b><i>Palacio de Congresos - Ground Floor</i></b>
                <br/>
                7.45 PM - Corporate Convention and Dinner <b>(LIVE STREAMING)</b>
                <br/>
                <b><i>Palacio de Congresos - Ground Floor</i></b>
                <br/>
                10.30 PM - End of Corporate Convention & Dinner and return to the Hotels (by foot)
            </p>
        </Box>
    )
}

export default Day1

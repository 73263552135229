import React, {useEffect, useState} from "react";
import HeaderLayout from "../Layouts/HeaderLayout";
import {Box, Button, CircularProgress, Collapse, Container, Tab, Tabs, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {DayContents} from "../components/DayContents";
import axios from "axios";
import {useParams} from "react-router-dom";
import {API_URL} from "../config";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ py: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const EventContents = () => {
    const {t} = useTranslation()
    const {day} = useParams()
    const [value, setValue] = React.useState(0);
    const [loading, setLoading] = useState(true)
    const [day1, setDay1] = useState({})
    const [day2, setDay2] = useState({})

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        getDati()
        setValue(day-1)
    },[])

    const getDati = async () => {
        setLoading(true)
        await axios.get(API_URL+'event-contents')
            .then(r => {
                console.log(r.data)
                setDay1(r.data.one)
                setDay2(r.data.two)
            })
        setLoading(false)
    }

    return (
        <HeaderLayout>
            <Container sx={{mt: 10, mb: 2}}>
                <Typography variant={"h2"} textTransform={"uppercase"}>
                    {t('content.title')}</Typography>
                <Typography variant={"h5"} sx={{mb: 1, mt: 2}}>
                    {t('content.until')}
                </Typography>
                <Typography variant={"h6"} sx={{mb: 1, mt: 2}} fontStyle={'italic'}>
                    {t('content.subtitle')}
                </Typography>
                {loading ? <CircularProgress/> : <Box>
                    <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label={t('content.day1')} {...a11yProps(0)}/>
                            <Tab label={t('content.day2')} {...a11yProps(1)}/>
                            <Tab label={t('content.day2-2')} {...a11yProps(2)}/>
                        </Tabs>
                    </Box>
                    <TabPanel value={value} index={0}>
                        <DayContents day={day1.presentations} index={0}/>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <DayContents day={day2.presentations.slice(0,9)} index={1}/>
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <DayContents day={day2.presentations.slice(9,13)}  index={2}/>
                    </TabPanel>
                </Box>}

            </Container>
        </HeaderLayout>
    )
}

export default EventContents

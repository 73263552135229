import React, {useEffect, useState} from 'react'
import Header from "../components/Header";
import {Backdrop, Box, Button, CircularProgress, Container, Grid, Stack, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import UserService from "../services/user.service";
import ThankYouStreaming from "./ThankYouStreaming";
import {motion, useMotionValue} from "framer-motion";
import SuccessCheck from "../components/SuccessCheck";
import HeaderLayout from "../Layouts/HeaderLayout";
import logo from '../assets/images/logo.png'
import {Link} from "react-router-dom";
import claim from '../assets/images/claim.png'

const agenda = [
    {
        day: 1,
        title: <span>DAY 1 - 9<sup>th</sup> May</span>,
    },
    {
        day: 2,
        title: <span>DAY 2 - 10<sup>th</sup> May</span>,
    },
    {
        day: 3,
        title: <span>DAY 3 - 11<sup>th</sup> May</span>,
    },
]

const Home = () => {
    const {t, i18n} = useTranslation()

    return (
        <HeaderLayout>
            <main style={{width: '100%', paddingTop: '5rem'}} className={'home'}>
                <Container maxWidth={"xl"} sx={{pt: 8, textAlign: 'center'}}
                           style={{color: 'white', fontFamily: 'Pressura'}}>
                    <Stack direction={"column"} spacing={{xs:3, md:3}} alignItems={"center"}>
                        <Stack direction={'row'} justifyContent={'center'} spacing={4}
                               alignItems={'center'} sx={{marginTop:'max(4rem, 12vh)'}}>
                            <Box>
                                <img src={logo} style={{width: '100%', maxWidth: '7.0rem'}}/>
                            </Box>
                            <Box sx={{width: 'min(35rem, 100%)'}}>
                                <img style={{width: '100%'}} src={require('../assets/images/date.png')}/>
                            </Box>
                        </Stack>
                        <Box sx={{mt:10}}>
                            <Box>
                                <div style={{marginBottom: '1rem'}}>
                                    <Button to={'/event-contents/1'} component={Link} variant={"contained"} style={{
                                        width: 'min(100%, 40rem)',
                                        fontWeight: 'bold',
                                        fontSize: 'min(2.2rem, 5.6vw)',
                                        textTransform: 'uppercase',
                                        margin: 0,
                                        lineHeight: 'min(3.2rem, 8vw)'
                                    }}>
                                        {t('content.title')}
                                    </Button>
                                </div>
                            </Box>
                        </Box>
                    </Stack>
                </Container>
            </main>
        </HeaderLayout>
    )
}

export default Home
